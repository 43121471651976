<template>
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col-10 headline">
        <h1 class="colorfull">Einzelnachweise</h1>
      </div>
      <div class="col-10 abschnitt">
        <p class="reading primary">1. Wie deine Daten verwendet werden,
          um dein Kaufverhalten zu beeinflussen.</p>
        <p class="miniText"><a class="link" href="https://www.estrategy-magazin.de/archiv/2021/ausgabe-01-2021/user-experience-und-mobile-first.html">User-Experience und Mobile First:</a> aufgerufen am 17. Jannuar 2022.</p>
        <p class="miniText"><a class="link" href="https://www.horizont.net/marketing/nachrichten/social-media-atlas-so-stark-beeinflussen-influencer-die-kaufentscheidungen-junger-menschen-192611">So stark beeinflussen Influencer die Kaufentscheidungen junger Menschen:</a> aufgerufen am 17. Jannuar 2022.</p>
      </div>

      <div class="col-10 abschnitt">
        <p class="reading primary">2. Wie deine Daten verwendet werden,
          um dich als Mensch zu bewerten.</p>
        <p class="miniText"><a class="link" href="https://www.dandc.eu/de/article/china-fuehrt-ein-punktesystem-zur-sozialen-bewertung-seiner-buerger-ein">Niemand kann sich entziehen:</a> aufgerufen am 17. Jannuar 2022.</p>
        <p class="miniText"><a class="link" href="https://www.kompetenzzentrum-kommunikation.de/artikel/marketing-online-bewertungen-615/">Marketing: Online-Bewertungen wichtiger als Gespräche im Freundeskreis:</a> aufgerufen am 17. Jannuar 2022.</p>
      </div>

      <div class="col-10 abschnitt">
        <p class="reading primary">3. Wie deine Daten verwendet werden,
          um dich für eigene Zwecke zu manipulieren.</p>
        <p class="miniText"><a class="link" href="https://www.spektrum.de/news/wie-algorithmen-uns-manipulieren/1849438">Die digitale Manipulation:</a> aufgerufen am 17. Jannuar 2022.</p>
        <p class="miniText"><a class="link" href="https://www.dw.com/de/so-manipuliert-social-media/a-55606935">So manipuliert Social Media:</a> aufgerufen am 17. Jannuar 2022.</p>
      </div>

      <div class="col-10 abschnitt">
        <p class="reading primary">4. Wie deine Daten verwendet werden,
          um deine politische Meinung zu beeinflussen.</p>
        <p class="miniText"><a class="link" href="https://www.bpb.de/gesellschaft/digitales/digitale-desinformation/290522/microtargeting-und-manipulation-von-cambridge-analytica-zur-europawahl">Microtargeting und Manipulation: Von Cambridge Analytica zur Europawahl:</a> aufgerufen am 17. Jannuar 2022.</p>
        <p class="miniText"><a class="link" href="https://www.dw.com/de/gmf-wie-medien-die-politik-beeinflussen-und-umgekehrt/a-55404083">GMF: Wie Medien die Politik beeinflussen - und umgekehrt:</a> aufgerufen am 17. Jannuar 2022.</p>
      </div>

      <div class="col-10 abschnitt">
        <p class="reading primary">5. Wie deine Daten verwendet werden,
          um dir gewisse Weltanschauungen zu vermitteln.</p>
        <p class="miniText"><a class="link" href="https://www.schau-hin.info/tipps-regeln/propaganda-im-netz-5-fragen-an-dr-lena-frischlich">Propaganda im Netz – 5 Fragen an Dr. Lena Frischlich:</a> aufgerufen am 17. Jannuar 2022.</p>
        <p class="miniText"><a class="link" href="https://www.mdr.de/medien360g/medienpolitik/digitale-meinungsbildung-100.html">Wie das Internet unsere Meinung beeinflusst:</a> aufgerufen am 17. Jannuar 2022.</p>
        <p class="miniText"><a class="link" href="https://www.zukunftsinstitut.de/artikel/big-data/dataismus-neues-weltbild-aus-dem-datenmeer/">Dataismus: Neues Weltbild aus dem Datenmeer:</a> aufgerufen am 17. Jannuar 2022.</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Quellen',
};
</script>

<style scoped>
.headline {
  padding: 10vh;
}
.miniText {
  margin: 0 3vh;
}
.link {
  color: #e0e0e0;
}
.abschnitt {
  margin: 2vh;
}
</style>
